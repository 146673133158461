@import '../../modules/styles/variable';

.bg-pri {
  background-color: $color-bright-cyan;
  color: white;

  &:hover {
    background-color: $color-light-cyan-active;
  }
}

.text-pri {
  color: $color-bright-cyan;
}

.footer-select {
  width: 72px;
}

.noWrap {
  white-space: 'nowrap';
}

.text-system {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #333 !important;
}

.select-invalid>* {
  color: red !important;
  border-color: red !important;
}

.buttonInput {
  padding: 6px 14px !important;
  margin-right: -5px
}

.inputAdornment {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.app-sidebar-menu .menu-item .menu-link {
  padding: 9px 12px !important;
  font-size: 14px;
}

.app-header-menu {
  .menu-item {
    align-self: stretch;
    display: flex;
    align-items: center;

    .menu-link {
      padding: 13px 20px;
    }
  }
}

.app-sidebar-wrapper {
  .menu-item {
    align-self: stretch;
    display: flex;
    align-items: center;

    .menu-link {
      padding: 13px 20px;
      width: 100%;
      
      .menu-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }

      .menu-icon {
        width: 16px;
      }
    }
  }
}

//table fiexd
.customs-collapse-row {
  font-size: 13px;

  .checkBox {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 16px;
      height: 16px;
      border-radius: 3px !important;
      cursor: pointer;
    }
  }

  .checkRadio {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 16px;
      height: 16px;
      border: 1px solid #5E6278;
      cursor: pointer;
    }
  }


  .table-container {
    overflow-x: auto;
  }

  .table-row-dashed {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-steel-blue !important;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
  }

  &:hover::-webkit-scrollbar {
    height: 10px !important;
    cursor: pointer !important;
  }

  .p-table {
    padding: 0.75rem 10px !important;
  }

  .td-vertical-center {
    text-align: center;
    vertical-align: middle !important;
  }
}

//input search
.searchTextField {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 1px solid #A9A9A9;
  width: 32px;
  justify-content: center;
  cursor: pointer;

  i {
    font-size: 16px;
  }

  &:hover {
    .icon {
      font-size: 18px;
      color: #13a7a4;
    }
  }
}
.verticalTabs {
  height: 100%;
  padding: 8px 8px 0;
  .titleTab {
    padding: 12px;
    border-radius: 8px;
    background-color: white;

    .nav-item {
      border-radius: 4px;
      .nav-link.active {
        color: white;
        background: var(--color-linear-teal);
      }
      &:hover {
        cursor: pointer;
        background: #b2dbd7;
        transition: all 0,5 linear;
      }
    }

  }

  .contentTab {
    background-color: white;
    border-radius: 8px;
    height: 100%;
    padding: 12px;
  }

  .collapseTitle {
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: #b2dbd7;
      transition: all 0,5 linear;
    }
  }

  .selectCollapse {
    margin-bottom: 8px;
  }

  .collapseWrapper {
    margin-left: 30px;
  }
}

.treeSelect {
  .truncate-text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
  }

  .rowTreeSelect {
    cursor: pointer;

    &:hover {
      background-color: $color-light-cyan-active;
    }
  }

  .svg-icon-folder {
    svg {
      width: 14px !important;
      height: 11px !important;
    }
  }

  .svg-icon-file {
    svg {
      width: 11px !important;
      height: 14px !important;
    }
  }

  .total-number {
    border-radius: 5px;
    font-size: 9px;
    font-weight: 600;
    width: 18px;
    width: 16px;
    padding: 2px;
    color: white;
    border: 1px solid $color-steel-blue;
    color: $color-steel-blue;
    display: inline-block;
    margin: auto;
    text-align: center;
  }
}

.badge {
  width: 31px;
  height: 20px;
  line-height: 20px;
  padding: 0;
  justify-content: center;
  align-items: center;
  left: 30px !important;
}

.check-box-autoComplete {
  input {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin: 0 0 0 5px;
  }
}

.sticky-column {
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  border: 0;
}

.advancedSearch {
  .verb {
    height: 29px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 4px 8px;
    border: 1px solid $color-border-table;
    margin: 0 10px;
  }

  .addField {
    margin-top: 16px;

    p {
      cursor: pointer;
      color: $color-steel-blue;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }
  }
}

.custom-tooltip {
  position: absolute;
  z-index: 100;

  .tooltip-inner {
    border-radius: 5px;
    padding: 4px 6px !important;
    background-color: $color-steel-blue !important;
    color: #fff !important;
  }

  .tooltip-arrow {
    display: none;
  }
}