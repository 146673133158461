@import "variable";

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}

.customs-input {
  height: 36px;
  font-size: 12px;
  // border-color: $royal-blue !important;
  border-color: $color-silver !important;
  border-radius: 6px;

  &.error {
    border-color: #f1416c !important;
  }

  &:disabled {
    border-color: $royal-blue !important;
  }
}

.resize-none {
    resize: none !important;
    height: auto !important;
}

.margin-x-auto {
    margin: 0 auto;
}

.scrollbar-width-none {
    scrollbar-width: none;
}

.customs-input-check {
    height: 20px;
    width: 20px;
    border-radius: 3px !important;
}

.searchTextField {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #A9A9A9;
    width: 32px;
    justify-content: center;
    cursor: pointer;

    i {
        font-size: 16px;
    }

    &:hover {
        i {
            font-size: 18px;
            color: #13a7a4;
        }
    }
}

.invalid-feedback-table {
  .invalid-feedback {
    font-style: italic;
    margin: 0;
    font-size: 10px;
    text-align: left;
    padding-left: 6px;
  }
}

textarea.customs-input {
  height: unset;
}

.h-auto-input {
  .customs-input {
    height: auto;
  }
}

.text-lable-input {
  display: flex;
  font-weight: 500;
  align-items: center;
}

.is-invalid {
  border-color: #f1416c !important;
}

.customs-input {
    height: 25px;
    font-size: $default-font-size;
    border-color: $color-border-gray !important;
    border-radius: 3px;
    padding: 0 4px;

    &:disabled {
        border-color: $color-border-gray !important;
    }

    &.ps-29px {
        padding-right: 29px !important;
    }

}

.custom-placeholder {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ac-is-invalid {
  ~.invalid-feedback {
    display: block;
  }

  >div {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
  }
}

.disabled-tab {
    pointer-events: none;
    cursor: not-allowed;
    color: #ccc;
}

//phiếu in
.dialog_body_scroll {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 !important;
}

.autocomplete-custom {
    span {
        &+div {
            display: flex;
            height: 25px;
            min-height: 25px;
            align-items: center;
        }
    }

    &>div {
        padding-right: 0;
        border-radius: 4px;
    }

    &.rounded-0>div {
        border-radius: 0;
    }

    &.radius>div {
        border-radius: 4px;
    }
}

.max-content-width {
    width: max-content;
}

.is-invalid {
    border-color: #f1416c !important
}

.form-control.is-invalid {
    border-color: #f1416c;
    padding-right: calc(1.5em + 1.55rem) !important;
    
}

.tooltip-inner {
    box-shadow: 2px 2px 5px #888 !important;
}

.text-cyan {
    color: $color-text-cyan !important;
}

.background-image-none {
  background-image: none !important;
}

.rect-img-container {
  width: 80px;
  height: 80px;
}

.rect-img-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.rect-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-form-check {
  align-items: flex-start !important;
  margin-top: 36px;

  input {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  label {
    flex: 1;
  }

  .form-check-input:checked~.form-check-label::before {
    background-color: $color-gray !important;
    border-color: $color-gray !important;
  }

  .form-check-input:indeterminate~.form-check-label::before {
    background-color: $color-gray !important;
    border-color: $color-gray !important;
  }

  .form-check-input[disabled]~.form-check-label,
  .form-check-input:disabled~.form-check-label {
    opacity: 0.8;
  }
}

.screen-h-grow {
  background-color: $color-white;
  height: calc(100vh - 108px);
  overflow-y: auto;
  overflow-x: hidden;
}

.z-50 {
  z-index: 50 !important;
}

.gap-col-modal {
  row-gap: 0.8rem;
}

.modal.modal-size-custom {
  max-height: 100vh;

  .modal-xl {
    --bs-modal-width: 90% !important;
  }

  .modal-header,
  .modal-body {
    padding: 12px;
  }
}

.modal_custom {
    background-color: rgb(0, 0, 0, 15%);
}

.bg-transparent {
  background: transparent !important;
}

.grid {
  display: grid;

  &.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  &.grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  &.gap-rem-1\.2 {
    gap: 1.2rem;
  }

  .col-span-6 {
    grid-column: span 6 / span 6;
  }

  .col-span-5 {
    grid-column: span 5 / span 5;
  }

  .col-span-4 {
    grid-column: span 4 / span 4;
  }

  .col-span-3 {
    grid-column: span 3 / span 3;
  }

  .col-span-2 {
    grid-column: span 2 / span 2;
  }

  .col-span-1 {
    grid-column: span 1 / span 1;
  }
}

.grid-cols-4 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.text-xl {
  font-size: 1.25rem;
}

.h-full {
  height: 100%;
}

.h-1\/2 {
  height: 50% !important;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.hidden {
  display: none !important;
}

.min-h-full {
  min-height: 100%;
}

.screen-h-grow-155 {
  background-color: $color-white;
  height: calc(100vh - 155px);
  overflow-y: auto;
  overflow-x: hidden;
}

.table-modal-h {
  .table-responsive {
    height: calc(100vh - 350px) !important;
  }
}

.bg-orange {
  background-color: $color-orange;
}

.bg-orange-dark {
  background-color: $color-dark-orange;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-5xl {
  border-radius: 2.5rem;
}

.svg-icon-md {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.btn-reset {
  outline: none;
  background-color: transparent;
  border: none;
}

.btn.btn-primary:disabled .svg-icon {
  color: $color-dark-blur-1 !important;
}

.w-1\/2 {
  width: 50% !important;
}

.sub-title {
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0;
  padding-top: 1.2rem;
}

.flex-row-label {
  display: flex;
  align-items: center;

  .text-lable-input {
    align-items: flex-start;
    font-weight: 400;
    flex-shrink: 0;
    margin: 5px 4px 0 0;
  }

  &>div {
    display: flex;
    width: 100%;
  }
}

.page-container {
  .tab-content {
    position: relative;
  
    .tab-pane {
        position: absolute;
        top: 0;
        left: 0;
        display: block !important;
        width: 100%;
        overflow: scroll;
    }
  
    .active {
        z-index: 1 !important;
    }
  
    .fade:not(.show) {
        opacity: 1 !important;
        z-index: 0;
    }
  }
}