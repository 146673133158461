.toggle-language {
    margin-right: 20px;
    position: relative;
};

.current-language:hover~.menu-sub {
    display: block;
};

.menu-sub {
    position: absolute;
    display: none;
    background-color: white;
    top: 30px;
    left: 16.5px;
    border-radius: 8px;
};

.menu-sub:hover {
    display: block;
};

.noti-container {
    position: relative;

    .noti-popup {
        top: 36px;
        right: -50px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        position: absolute;
        width: 320px;
        max-height: 600px;
        overflow-y: auto;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
}